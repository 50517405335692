@for (app of apps(); track app.applicationID) {
  @if (app.hasAccess === true) {
    <a
      target="_self"
      [routerLink]="[app.applicationUrl]"
      [queryParamsHandling]="'merge'"
      [attr.title]="app.applicationName | translate"
      [attr.disabled]="app.hasAccess !== true ? true : null"
      [attr.aria-label]="app.applicationName"
      [ngClass]="{ app: true, active: activeAppID() === app.applicationID }">
      @switch (app.applicationID) {
        @case (1) {
          <app-icon-app-energy></app-icon-app-energy>
        }
        @case (2) {
          <app-icon-app-advise></app-icon-app-advise>
        }
        @case (3) {
          <app-icon-app-sense></app-icon-app-sense>
        }
        @case (4) {
          <app-icon-app-facility></app-icon-app-facility>
        }
        @case (5) {
          <app-icon-app-invoice></app-icon-app-invoice>
        }
      }
    </a>
  }
}
