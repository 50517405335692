import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { AppService } from '@logic-suite/shared/app.service';
import { Application } from '../../access/access.model';
import { AccessService } from '../../access/access.service';

@Component({
  selector: 'app-app-menu',
  templateUrl: './app-menu.component.html',
  styleUrls: ['./app-menu.component.scss'],
  standalone: false,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppMenuComponent {
  private access = inject(AccessService);
  private app = inject(AppService);

  apps = toSignal<Application[]>(this.access.getApplicationList());
  activeAppID = toSignal(this.app.application$);
}
